<template>
  <div class="col p-0 pb-10">
    <mobile-header>
      <template v-slot:title>
        <h1 class="title m-0">{{ $route.meta.title }}</h1>
      </template>
      <template v-slot:subtitle>
        <h1 class="subtitle m-0">{{ $route.meta.subtitle }}</h1>
      </template>
    </mobile-header>
    <mobile-sub-header
      v-if="$route.meta.title === 'Dispositivos' && $route.params.device"
    ></mobile-sub-header>
    <router-view></router-view>
  </div>
</template>

<script>
import MobileHeader from "@/components/Mobile/MobileHeader";
import MobileSubHeader from "@/components/Mobile/MobileSubHeader";

import { mapGetters } from "vuex";
// let mobile = require("is-mobile");

export default {
  name: "mobile-layout",

  components: {
    MobileHeader,
    MobileSubHeader,
  },
  computed: {
    ...mapGetters("mobile", ["getVersion"]),
  },
  data: () => ({}),
  // beforeMount() {
  //   if (
  //     !mobile({ tablet: true }) ||
  //     (mobile({ tablet: true }) && this.getVersion === "web")
  //   )
  //     this.$router.push({ name: "dashboard" });
  // },
};
</script>

<style lang="sass">
</style>