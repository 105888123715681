var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-center align-center sub-header-container w-100"},[_c('router-link',{staticClass:"nav-item col-3",attrs:{"to":{
      name: 'mobileDeviceDetail',
      params: { device: _vm.$route.params.device },
    }}},[_c('div',{staticClass:"row d-flex justify-center align-center",style:(_vm.$route.name === 'mobileDeviceDetail'
          ? 'color: #23c577!important'
          : 'color: #BDBDBD!important')},[_c('span',[_vm._v("Detalles")])])]),_c('router-link',{staticClass:"nav-item col-3",attrs:{"to":{
      name: 'mobileDeviceSlots',
      params: { device: _vm.$route.params.device },
    }}},[_c('div',{staticClass:"row d-flex justify-center align-center",style:(_vm.$route.name === 'mobileDeviceSlots'
          ? 'color: #23c577!important'
          : 'color: #BDBDBD!important')},[_c('span',[_vm._v("Slots")])])]),_c('router-link',{staticClass:"nav-item col-3",attrs:{"to":{
      name: 'mobileDeviceMonitoringLatest',
      params: { device: _vm.$route.params.device },
    }}},[_c('div',{staticClass:"row d-flex justify-center align-center",style:(_vm.$route.name === 'mobileDeviceMonitoringLatest'
          ? 'color: #23c577!important'
          : 'color: #BDBDBD!important')},[_c('span',[_vm._v("Último registro")])])]),_c('router-link',{staticClass:"nav-item col-3",attrs:{"to":{
      name: 'mobileDeviceMonitoringHistorical',
      params: { device: _vm.$route.params.device },
    }}},[_c('div',{staticClass:"row d-flex justify-center align-center",style:(_vm.$route.name === 'mobileDeviceMonitoringHistorical'
          ? 'color: #23c577!important'
          : 'color: #BDBDBD!important')},[_c('span',[_vm._v("Históricos")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }