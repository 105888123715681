<template>
  <div class="row justify-center align-center sub-header-container w-100">
    <router-link
      :to="{
        name: 'mobileDeviceDetail',
        params: { device: $route.params.device },
      }"
      class="nav-item col-3"
      ><div
        class="row d-flex justify-center align-center"
        :style="
          $route.name === 'mobileDeviceDetail'
            ? 'color: #23c577!important'
            : 'color: #BDBDBD!important'
        "
      >
        <span>Detalles</span>
      </div></router-link
    >
    <router-link
      :to="{
        name: 'mobileDeviceSlots',
        params: { device: $route.params.device },
      }"
      class="nav-item col-3"
      ><div
        class="row d-flex justify-center align-center"
        :style="
          $route.name === 'mobileDeviceSlots'
            ? 'color: #23c577!important'
            : 'color: #BDBDBD!important'
        "
      >
        <span>Slots</span>
      </div></router-link
    >
    <router-link
      :to="{
        name: 'mobileDeviceMonitoringLatest',
        params: { device: $route.params.device },
      }"
      class="nav-item col-3"
      ><div
        class="row d-flex justify-center align-center"
        :style="
          $route.name === 'mobileDeviceMonitoringLatest'
            ? 'color: #23c577!important'
            : 'color: #BDBDBD!important'
        "
      >
        <span>Último registro</span>
      </div></router-link
    >
    <router-link
      :to="{
        name: 'mobileDeviceMonitoringHistorical',
        params: { device: $route.params.device },
      }"
      class="nav-item col-3"
      ><div
        class="row d-flex justify-center align-center"
        :style="
          $route.name === 'mobileDeviceMonitoringHistorical'
            ? 'color: #23c577!important'
            : 'color: #BDBDBD!important'
        "
      >
        <span>Históricos</span>
      </div></router-link
    >
  </div>
</template>

<script>
export default {
  name: "mobile-sub-header",
};
</script>

<style lang="sass">
.sub-header-container
  background-color: white
  height: 40px
  margin: 0
  -webkit-box-shadow: 0px -2px 10px 0px rgba(35,197,119,0.5)
  box-shadow: 0px -2px 10px 0px rgba(35,197,119,0.5)
  .nav-item
    color: #23c577
</style>